import serviceConfigs from './config'

export default class UserGroupService {
  // Will be used by this service for making API calls
  axiosIns = null

  // serviceConfigs <= Will be used by this service
  authConfig = { ...serviceConfigs }

  constructor(axiosIns, serviceOverrideConfig) {
    this.axiosIns = axiosIns
    this.serviceConfigs = { ...this.authConfig, ...serviceOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.authConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => error,
    )
  }

  create(...args) {
    return this.axiosIns.post(this.serviceConfigs.userGroupCreateEndpoint, ...args)
  }
}
